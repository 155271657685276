export const LOW_BALANCE = 'Amount exceeded the account balance!';
export const BALANCE_LIMIT = 'Amount exceeded the transaction limit!';
export const EMAIL_FORMAT_MESSAGE = 'Email format invalid!';
export const EMAIL_REQUIRED_MESSAGE = 'Email is required!';
export const PASSWORD_FORMAT_MESSAGE =
  'Password must be at least 8 characters and contain at least 1 capital letter and 1 number';
export const PASSWORD_REQUIRED_MESSAGE = 'Password is required!';
export const CODE_FORMAT_MESSAGE = 'Entered value should be only number!';
export const FIRSTNAME_REQUIRED_MESSAGE = 'First name is required!';
export const LASTNAME_REQUIRED_MESSAGE = 'Last name is required!';
export const PHONE_FORMAT_MESSAGE = "Phone number format isn't correct!";
export const PASSWORD_DOESNT_MATCH_MESSAGE = "Password doesn't match!";
export const REQUIRE_MESSAGE = 'This field is required!';
export const PASSWORD_FORMAT_EXPRESSION =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;
export const SELECT_REQUIRE_MESSAGE = 'This field is required!';
export const RECIPIENT_REQUIRE_MESSAGE = 'Select at least one recipient';
export const FILE_REQUIRE_MESSAGE = 'File is required!';
export const PASSCODE_REQUIRED_MESSAGE = 'Passcode is required!';
export const FORMAT_MESSAGE = "Format isn't correct!";
export const FIRST_NAME_REQUIRED_MESSAGE = 'First name is required!';
export const LAST_NAME_REQUIRED_MESSAGE = 'Last name is required!';
export const RECEIVER_NAME_INCORRECT_MESSAGE = 'Recipient name is incorrect!';
export const TRANSFER_AMOUNT_FORMAT_MESSAGE =
  'Transfer amount must be a number!';
export const TRANSFER_AMOUNT_MIN_MESSAGE =
  'Transfer amount must be greater than 0!';
export const SELECT_THE_CORECT_FILE_TYPE =
  'Only the following format is accepted: .csv';
